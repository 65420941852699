import React from "react";

export default () => (
	<div className="bg-gray-90 ">
		<div className="text-white site-padding-vertical bg-grid-raster-white-center">
			<div className="edge align-baseline  trim fit-down">
				<h1>Space.</h1>
				<p className="text-preset-large-intro max-char-45">The main goal of the SpaceFramework is to create <q>Consistency in space</q>. This is done by creating equal groups of space between each and every
					component.</p>
				<aside className="border">
					<p><strong>Did you know: </strong>The SpaceFramework isn't just for for developers, it's also made
						for
						UX & UI Designers. It is a common way of thinking and creating by using the same patterns and
						rhythms.</p>
				</aside>

				<p>The SpaceFramework aims to create equal amounts of space between each and every component in the
					website. By doing so the webpage looks calmer, you feel the consistency and you can create vertical
					rhythms and patterns. This affects Interaction / UX Design, graphic design and the way developers
					develop their websites.</p>
				<p>Equal space is created in two ways:</p>
				<ul>
					<li>Using a common unit. In the SpaceFramework we call this the <dfn>Space-Unit</dfn>.</li>
					<li>Creating groups of spaces. This we call <dfn>Layout-Presets</dfn>.</li>
				</ul>

				<h2>Space Units</h2>
				<p className="text-preset-medium-intro">The whole SpaceFramework is based on one single unit: the <dfn>space-unit</dfn>.
				</p>
				<p>The
					<dfn>space-unit</dfn> represents a virtual grid and is nothing more and nothing less then a unit to
					calculate all paddings, margins, widths, height, positions etc.</p>
				<p>The size of the <dfn>space-unit</dfn> can different for different viewport sizes. This way you can
					make everything look more narrow on mobile and more broad on desktop. The default size is 16px but
					you could also work with a 8px grid or anything else you like.</p>

				<div className="grid medium-grid-3 block-margin-vertical">
					<div className="flex">
						<div><span className="space-unit" /></div>
						<div className="trim fit-down"><p>This red block has a width and height of 1 space-unit</p></div>
					</div>
					<div className="flex">
						<div><span className="space-unit-2" /></div>
						<div className="trim fit-down"><p>This red block has a width and height of 2 space-units</p></div>
					</div>
					<div className="flex">
						<div><span className="space-unit-3" /></div>
						<div className="trim fit-down"><p>This red block has a width and height of 3 space-units</p></div>
					</div>
				</div>
				<h2>Padding</h2>
				<p className="text-preset-medium-intro">The paddings in the examples are defined with space-units</p>
				<p>Notice how the padding matches the raster grid.</p>
				<div className="grid medium-grid-2 large-grid-4 align-items-start block-margin-vertical">
					<div className="padding-example-1 bg-padding">
						<div className="bg-grid-raster-dark">
							Padding of 1 space unit
						</div>
					</div>
					<div className="padding-example-2 bg-padding">
						<div className="bg-grid-raster-dark">
							Padding of 2 space units
						</div>
					</div>
					<div className="padding-example-3 bg-padding">
						<div className="bg-grid-raster-dark">
							Padding of 3 space units
						</div>
					</div>
					<div className="padding-example-4 bg-padding">
						<div className="bg-grid-raster-dark">
							Padding of 4 space units
						</div>
					</div>
				</div>

				<div className="grid medium-grid-3 large-grid-6 block-margin-vertical">
					<div className="padding-example-5 bg-padding grid">
						<div className="bg-grid-raster-dark">
							Vertical padding of 2 space unit
						</div>
					</div>
					<div className="padding-example-6 bg-padding grid">
						<div className="bg-grid-raster-dark">
							Horizontal padding of 2 space units
						</div>
					</div>
					<div className="padding-example-7 bg-padding grid">
						<div className="bg-grid-raster-dark">
							Top padding of 1 space units
						</div>
					</div>
					<div className="padding-example-8 bg-padding grid">
						<div className="bg-grid-raster-dark">
							Right padding of 1 space units
						</div>
					</div>
					<div className="padding-example-9 bg-padding grid">
						<div className="bg-grid-raster-dark">
							Bottom padding of 1 space units
						</div>
					</div>
					<div className="padding-example-10 bg-padding grid">
						<div className="bg-grid-raster-dark">
							Left padding of 1 space units
						</div>
					</div>
				</div>

				<h2>Margin</h2>
				<p className="text-preset-medium-intro">The margins in the examples are defined with space-units</p>
				<p>Notice how the margin matches the raster grid.</p>
				<div className="grid medium-grid-2 large-grid-4 block-margin-vertical align-items-start">
					<div className="bg-margin grid">
						<div className="margin-example-1 bg-grid-raster-dark">
							Margin of 1 space unit
						</div>
					</div>
					<div className="bg-margin grid">
						<div className="margin-example-2 bg-grid-raster-dark">
							Margin of 2 space units
						</div>
					</div>
					<div className="bg-margin grid">
						<div className="margin-example-3 bg-grid-raster-dark">
							Margin of 3 space units
						</div>
					</div>
					<div className="bg-margin grid">
						<div className="margin-example-4 bg-grid-raster-dark">
							Margin of 4 space units
						</div>
					</div>
				</div>

				<div className="grid medium-grid-3 large-grid-6 block-margin-vertical">
					<div className="bg-margin grid">
						<div className="margin-example-5 bg-grid-raster-dark">
							Vertical margin of 2 space unit
						</div>
					</div>
					<div className="bg-margin grid">
						<div className="margin-example-6 bg-grid-raster-dark">
							Horizontal margin of 2 space units
						</div>
					</div>
					<div className="bg-margin grid">
						<div className="margin-example-7 bg-grid-raster-dark">
							Top margin of 1 space units
						</div>
					</div>
					<div className="bg-margin grid">
						<div className="margin-example-8 bg-grid-raster-dark">
							Right margin of 1 space units
						</div>
					</div>
					<div className="bg-margin grid">
						<div className="margin-example-9 bg-grid-raster-dark">
							Bottom margin of 1 space units
						</div>
					</div>
					<div className="bg-margin grid">
						<div className="margin-example-10 bg-grid-raster-dark">
							Left margin of 1 space units
						</div>
					</div>
				</div>

				<h2>Layout presets</h2>
				<p className="text-preset-medium-intro max-char-45">By centralizing all spacings at one place, you can
					easily apply common spacings patterns. The spacings can be different on different viewport sizes.
					You find the centralized spacings at <dfn>layout-presets</dfn></p>
				<h3>Default layout-presets</h3>
				<p>The SpaceFramework includes 3 default layout-presets. Which can be overwritten by name and
					values to
					match your needs:</p>
				<ul>
					<li>Site</li>
					<li>Section</li>
					<li>Block</li>
				</ul>

				<div className="grid medium-grid-2 large-grid-4 block-margin-vertical">
					<div className="trim fit-down">
						<h3>Spacing types</h3>
						<p>A layout-preset has different types of spacing:</p>
						<ul>
							<li>Padding</li>
							<li>Margin</li>
							<li>Position</li>
						</ul>
					</div>
					<div className="trim fit-down">
						<h3>Directions</h3>
						<p>A layout-preset has different space directions:</p>
						<ul>
							<li>All sides</li>
							<li>Vertical and >Horizontal</li>
							<li>Top, Right, Bottom and Left</li>
						</ul>
					</div>
					<div className="trim fit-down">
						<h3>Viewports</h3>
						<p>A layout-preset can be applied on a certain viewport:</p>
						<ul>
							<li>Small</li>
							<li>Medium</li>
							<li>Large</li>
						</ul>
					</div>
					<div className="trim fit-down">
						<h3>Negative</h3>
						<p>Margins and positions can be negative values</p>
						<ul>
							<li>Negative</li>
						</ul>
					</div>
				</div>

				<h3>Site layout-preset</h3>
				<div className="grid grid-2 block-margin-vertical">
					<div className="bg-padding grid">
						<div className="site-padding">
							<div className="bg-gray-90">
								<dfn>site</dfn> layout-preset with padding
							</div>
						</div>
					</div>
					<div className="bg-margin grid">
						<div className="site-margin bg-gray-90">
							<dfn>site</dfn> layout-preset with margin
						</div>
					</div>
				</div>

				<h3>Section layout-preset</h3>
				<div className="grid grid-2 block-margin-vertical">
					<div className="bg-padding grid">
						<div className="section-padding">
							<div className="bg-gray-90">
								<dfn>section</dfn> layout-preset with padding
							</div>
						</div>
					</div>
					<div className="bg-margin grid">
						<div className="section-margin bg-gray-90">
							<dfn>section</dfn> layout-preset with margin
						</div>
					</div>
				</div>

				<h3>Block layout-preset</h3>
				<div className="grid grid-2 block-margin-vertical">
					<div className="bg-padding grid">
						<div className="block-padding">
							<div className="bg-gray-90">
								<dfn>block</dfn> layout-preset with padding
							</div>
						</div>
					</div>
					<div className="bg-margin grid">
						<div className="block-margin bg-gray-90">
							<dfn>block</dfn> layout-preset with margin
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);