import React from "react"
//import { Link } from "gatsby"

import Layout from "../../components/layout"
import Space from "../../components/space"
import SEO from "../../components/seo"

const SpacePage = () => (
  <Layout>
    <SEO title="Space" />
    <Space />
  </Layout>
)

export default SpacePage
